﻿"use strict";

/* Controllers */
var _START_SHOWING_COUNT = 100;
var _CURRENT_SHOWING_COUNT = 100;
var _MAX_SHOWING = false;

angular
    .module("AccountManagerApp")
    .controller(
        "ChooseAccountController",
        function ChooseAccountController($scope, $rootScope, $timeout, $http) {
            $scope.accountOptions = new Array();
            $scope.slugFilter = "";
            $scope.excludeInternal = true;
            $scope.showType = "SA";
            $scope.accounts = new Array();
            $scope.pointAvarage = 0;
            $scope.bannerFlowUrl = bannerFlowUrl;
            $scope.loading = false;
            $scope.ranges = {
                "All time": [moment("2014-01-01"), moment()],
                Today: [moment(), moment()],
                Yesterday: [moment().subtract("days", 1), moment().subtract("days", 1)],
                "Last 7 Days": [moment().subtract("days", 6), moment()],
                "Last 30 Days": [moment().subtract("days", 29), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [
                    moment().subtract("month", 1).startOf("month"),
                    moment().subtract("month", 1).endOf("month")
                ]
            };
            $scope.sweLocale = { firstDay: 1 };
            $scope.dateRange = { startDate: moment("2014-01-01"), endDate: moment() };
            $scope.hasPressedShowAll = false;

            $http({ method: "GET", url: "api/v1/accounts/all" }).success(function (data) {
                $scope.data = data.accounts;

                var today = new Date();
                var day = 1000 * 60 * 60 * 24;

                for (var i = 0; i < $scope.data.length; i++) {
                    if ($scope.data[i].adwords) {
                        var keyword = $scope.data[i].adwords.keyword
                            ? "\nKeyword: " + $scope.data[i].adwords.keyword
                            : "";
                        var match = $scope.data[i].adwords.match
                            ? "\nMatch: " + $scope.data[i].adwords.match
                            : "";

                        $scope.data[i].sourceString =
                            "Utm Campaign:" +
                            $scope.data[i].adwords.utmCampaign +
                            "\n" +
                            "Utm Term: " +
                            $scope.data[i].adwords.utmTerm +
                            "\n" +
                            "Utm Source: " +
                            $scope.data[i].adwords.utmSource +
                            "\n" +
                            "Utm Medium: " +
                            $scope.data[i].adwords.utmMedium +
                            "\n" +
                            "Utm Content: " +
                            $scope.data[i].adwords.utmContent +
                            keyword +
                            match;
                    } else {
                        $scope.data[i].sourceString = null;
                    }
                }
                $scope.accounts = $scope.data;

                $scope.doSearch();
            });

            $scope.$watch("slugFilter", function (newVal, oldVal) {
                if (newVal === oldVal) return;

                $scope.doSearch();
            });

            $scope.$watch("showType", function (newVal, oldVal) {
                if (newVal === oldVal) return;

                $scope.doSearch();
            });

            $scope.$watch("excludeInternal", function (newVal, oldVal) {
                if (newVal === oldVal) return;

                $scope.doSearch();
            });

            $scope.$watch("dateRange.startDate", function (newVal, oldVal) {
                if (newVal === oldVal) return;

                $scope.doSearch();
            });

            $scope.$watch("dateRange.endDate", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.getTitle = function () {
                switch ($scope.showType) {
                    case "SA":
                        return "Small advertiser accounts";
                    case "ENTERPRISE":
                        return "Enterprise accounts";
                    case "AGENCY":
                        return "Agency accounts";
                    case "PARTNERS":
                        return "Partner accounts";
                }
            };

            $scope.isPlanCodes = function (plan, planCodes) {
                for (var i = 0; i < planCodes.length; i++) {
                    if (plan.planCode === planCodes[i]) {
                        return true;
                    }
                }
                return false;
            };

            $scope.search = function (item) {
                if (!$scope.accounts) return null;

                var totalPoints = 0;

                for (var i = 0; i < $scope.accounts.length; i++) {
                    totalPoints += $scope.accounts[i].points;
                }

                $scope.pointAvarage = ((totalPoints * 1.0) / $scope.accounts.length).toFixed(2);

                if (item.plans.length === 0) {
                    console.error("Account got no plan, accountSlug: " + item.slug);
                    return item;
                }
                //Is the account created within the date range?
                if (
                    ($scope.showType === "ENTERPRISE" && item.plans[0].planCode === "bf-enterprise") ||
                    ($scope.showType === "AGENCY" && item.plans[0].planCode === "bf-agency") ||
                    ($scope.showType === "SA" &&
                        $scope.isPlanCodes(item.plans[0], [
                            "bf-1-m",
                            "bf-1-y",
                            "bf-2-m",
                            "bf-2-y",
                            "bf-3-m",
                            "bf-3-y",
                            "bf-s-m",
                            "bf-s-y",
                            "bf-m-m",
                            "bf-m-y",
                            "bf-l-m",
                            "bf-l-y"
                        ])) ||
                    ($scope.showType === "PARTNERS" &&
                        item.plans[0].planCode != "bf-agency" &&
                        item.plans[0].planCode != "bf-enterprise" &&
                        !$scope.isPlanCodes(item.plans[0], [
                            "bf-1-m",
                            "bf-1-y",
                            "bf-2-m",
                            "bf-2-y",
                            "bf-3-m",
                            "bf-3-y",
                            "bf-s-m",
                            "bf-s-y",
                            "bf-m-m",
                            "bf-m-y",
                            "bf-l-m",
                            "bf-l-y"
                        ]))
                ) {
                    if (
                        !moment(item.created).isBefore($scope.dateRange.startDate) &&
                        !moment(item.created).isAfter($scope.dateRange.endDate)
                    ) {
                        //Is the account an internal account?
                        if (
                            $scope.excludeInternal ||
                            (!$scope.excludeInternal &&
                                !(
                                    "" + item.owner.email.indexOf("nordicfactory.com") != -1 ||
                                    "" + item.owner.email.indexOf("@bannerflow.com") != -1 ||
                                    "" + item.owner.email.indexOf("@translationflow.com") != -1
                                ))
                        ) {
                            //Is the account filtered by the slugFilter?
                            if (
                                "" + item.owner.firstName.indexOf($scope.slugFilter) != -1 ||
                                "" + item.slug.indexOf($scope.slugFilter) != -1 ||
                                "" + item.plans[0].planCode.indexOf($scope.slugFilter) != -1 ||
                                "" + item.owner.email.indexOf($scope.slugFilter) != -1
                            ) {
                                return item;
                            }
                        }
                    }
                }
                return null;
            };

            $scope.doSearch = function () {
                //$scope.loading = true;
                //$timeout(function () {
                $scope.accounts = new Array();
                for (var i = 0; i < $scope.data.length; i++) {
                    var res = $scope.search($scope.data[i]);
                    if (res) {
                        $scope.accounts.push(res);
                    }
                }
                //   $scope.loading = false;
                //}, 50);
            };

            $scope.loading = false;

            $scope.isMaxShowing = function () {
                return _MAX_SHOWING;
            };

            $scope.showMore = function () {
                _CURRENT_SHOWING_COUNT += 25;
                $scope.hasPressedShowAll = true;
            };

            $scope.createEnterpriseAccount = function () {
                var name = prompt("Please enter the name of the new enterprise account", "");
                if (name != null) {
                    window.location = "/api/v1/enterprise/" + encodeURIComponent(name);
                }
            };

            $scope.createAgencyAccount = function () {
                var name = prompt("Please enter the name of the new agency account", "");
                if (name != null) {
                    window.location = "/api/v1/agency/" + encodeURIComponent(name);
                }
            };

            /*
        $scope.makeNotDemo = function (account) {

            var name = prompt("Are you sure you want to change this account from demo to live status? Is the name correct? If not change it.", account.name);
            if (name) {
                $http({ method: 'PUT', url: "api/v1/" + account.slug + "/enterprise", data: { inTrial: false, name: name } }).success(function (data) {
                    account.isInBannerFlowTrial = false;
                    account.name = name;
                });
            } else {

            }

        };*/

            $scope.changeEmailSettings = function (account) {
                var method = account.isIncludedInEmailReports ? "include" : "exclude";
                $http({
                    method: "GET",
                    url: "api/v1/" + account.slug + "/admin/emails/" + method
                }).success(function (data) {});
            };

            $scope.resendConfirmationEmail = function (account) {
                var resend = confirm("Resend confirmation email to " + account.owner.email + "?");
                if (resend) {
                    $http({
                        method: "GET",
                        url: "api/v1/" + account.slug + "/admin/emails/resend/confirmation"
                    }).success(function (data) {
                        alert("Email resent to " + account.owner.email + "!");
                    });
                }
            };

            $scope.extendTrial = function (account) {
                var extraDays = prompt("In how many days should the trial end (from today)?", "");
                //Check if input is integer
                if (extraDays) {
                    if (!isNaN(extraDays) && extraDays % 1 === 0 && extraDays > 0 && extraDays <= 100) {
                        $http({
                            method: "GET",
                            url: "api/v1/" + account.slug + "/admin/extendTrial/" + extraDays
                        }).success(function (data) {
                            account.plans[0].periodEnds = moment().add("days", extraDays).format();
                            alert("Trial extended to " + moment().add("days", extraDays).format("ll"));
                        });
                    } else alert("Input must be a number between 1 and 100");
                }
            };

            $scope.addImpressions = function (account) {
                var extraImpr = prompt("Add how many extra impressions?", "");
                //Check if input is integer
                if (extraImpr) {
                    if (
                        !isNaN(extraImpr) &&
                        extraImpr % 1 === 0 &&
                        extraImpr > 0 &&
                        extraImpr <= 100000000
                    ) {
                        $http({
                            method: "GET",
                            url: "api/v1/" + account.slug + "/admin/extraimpressions/" + extraImpr
                        }).success(function (data) {
                            alert(extraImpr + " impressions added!");
                        });
                    } else alert("Input must be a number between 1 and 100 000 000");
                }
            };

            $scope.addDynCont = function (account) {
                var generate = confirm("Add dynamic content to account?");
                if (generate) {
                    $http({
                        method: "GET",
                        url: "api/v1/" + account.slug + "/admin/feature/add/Feeds"
                    }).success(function (data) {
                        alert("Dynamic content added to account!");
                    });
                }
            };

            $scope.removeDynCont = function (account) {
                var generate = confirm("Remove dynamic content from account?");
                if (generate) {
                    $http({
                        method: "GET",
                        url: "api/v1/" + account.slug + "/admin/feature/remove/Feeds"
                    }).success(function (data) {
                        alert("Dynamic content removed from account!");
                    });
                }
            };

            $scope.generatePlacements = function (account) {
                var generate = confirm("Re-generate all banners?");
                if (generate) {
                    $http({
                        method: "GET",
                        url: "api/v1/" + account.slug + "/admin/regenerate/banners/"
                    }).success(function (data) {
                        alert(data + " banners queued up to be re-generated!");
                    });
                }
            };

            $scope.changePlan = function (account) {
                var planCode = prompt("Change plan? Enter plan code...", "");
                if (planCode) {
                    $http({
                        method: "GET",
                        url: "api/v1/" + account.slug + "/admin/plan/change/" + planCode
                    }).success(function (data) {
                        alert("Plan changed to: " + planCode);
                    });
                }
            };

            /*
        $scope.data = new Array();
        for (var i = 0; i < 2000; i++) {
            $scope.data.push({
                "id": "533c6cc37b18ed0770df2930",
                "slug": "cnya",
                "isInBannerFlowTrial": false,
                "isConfirmed": true,
                "isLocalyClosed": false,
                "isRecurlyClosed": true,
                "adwords": {
                    "keyword": "testkeyword",
                    "match": "testmatch",
                    "utmCampaign": "utmtest"
                },
                "plans": [
                  {
                      "id": "533c58e90b807d18e06d96d3",
                      "created": "2014-04-01T18:37:29.781Z",
                      "modified": "2014-04-02T18:37:29.781Z",
                      "trashed": "0001-01-01T00:00:00Z",
                      "planCode": "bf-1-y",
                      "name": "Standard (annually)",
                      "uuid": "2692e1c03d59d75a007d1f4d2ba2d24d",
                      "product": 1,
                      "isDowngrading": false,
                      "planState": 1,
                      "allowedAddons": [],
                      "usingAddons": [],
                      "limitations": [
                        {
                            "code": "users",
                            "amount": 1
                        },
                        {
                            "code": "brands",
                            "amount": 1
                        },
                        {
                            "code": "impressions",
                            "amount": 250000
                        }
                      ],
                      "features": [
                        "Editor",
                        "Creator",
                        "Publicer",
                        "Analyzer",
                        "Settings"
                      ],
                      "pricing": {
                          "unitAmountInCents": 58800,
                          "addonBrandUnitAmountInCents": 0,
                          "addonUserUnitAmountInCents": 0
                      },
                      "periodEnds": "2015-04-02T20:17:05Z",
                      "payment": "0001-01-01T00:00:00Z"
                  }
                ],
                "users": 1,
                "brands": 0,
                "owner": {
                    "email": "jacobsson.daniel@gmail.com",
                    "firstName": "Daniel " + i,
                    "lastName": "Jakobsson",
                    "timeZone": null,
                    "externalId": null,
                    "settings": null,
                    "trackingId": "00000000-0000-0000-0000-000000000000"
                },
                "created": "2014-04-11T20:02:11.305Z",
                "star": false,
                "points": 1,
                "comment": null
            });
        }
        $scope.accounts = $scope.data;
        $scope.doSearch();*/
        }
    );
