﻿angular
    .module("AccountManagerApp")
    .controller(
        "CreateAccountDialogController",
        function CreateAccountDialogController($scope, $rootScope, $timeout, $http, CMPFeatureSet) {
            $scope.model = {
                type: $scope.ngDialogData.type,
                name: "",
                done: false,
                loading: false,
                bannerFlowUrl: bannerFlowUrl,
                createdSlug: "",
                industryOptions: window.viewData.industries,
                industry: null,
                endDate: null,
                impressions: null,
                salesRepOptions: window.viewData.salesReps,
                salesRep: null,
                accountCreated: null
            };

            $scope.createAccount = function () {
                if ($scope.model.industry == null || $scope.model.name == "") return;
                if ($scope.model.type === "trial") {
                    if (
                        ($scope.model.endDate === null,
                        $scope.model.impressions === null,
                        $scope.model.salesRep === null)
                    )
                        return;
                }
                $scope.model.loading = true;

                const shouldSetCMPFeatures =
                    $scope.model &&
                    ($scope.model.type === "enterprise" || $scope.model.type === "trial");

                if ($scope.model.name != "") {
                    var data = {
                        name: $scope.model.name,
                        industry: $scope.model.industry,
                        trialendDate: $scope.model.endDate,
                        totalAmountOfImpressions: $scope.model.impressions,
                        salesRep: $scope.model.salesRep
                    };

                    if (shouldSetCMPFeatures) {
                        $http({
                            method: "POST",
                            url: "/api/v1/{0}".format($scope.model.type),
                            data: data
                        }).success(function (data) {
                            $scope.model.accountCreated = $scope.model.bannerFlowUrl + data.slug;
                            $scope.model.createdSlug = data.slug;
                            $scope.model.loading = false;
                            $scope.model.done = true;

                            $http({
                                method: "POST",
                                url: "api/v1/adminpanel/" + data.id,
                                data: CMPFeatureSet
                            });
                        });
                    }
                }
            };
        }
    );
