﻿angular
    .module("AccountManagerApp")
    .controller(
        "AccountDetailsController",
        function AccountDetailsController($scope, $rootScope, $timeout, $http, $q) {
            $scope.currentAccount = null;
            $scope.selectedPackage = null;
            $scope.selectedIndustry = null;
            $scope.selectedSalesRep = null;
            $scope.selectedCsm = "Not set";
            $scope.loading = true;
            $scope.deniedMessage =
                "-- Request denied -- Talk to the tech team to change account features";

            $scope.industries = window.viewData.industries;
            $scope.salesreps = window.viewData.salesReps;
            $scope.csms = window.viewData.csms;

            $scope.loginTypes = [
                { key: "Password", name: "Password" },
                { key: "Sso", name: "SSO" },
                { key: "SsoAndPassword", name: "SSO and Password" }
            ];
            $scope.isFeaturesDirty = false;
            $scope.isPackageDirty = false;

            $scope.init = function () {
                if ($scope.featureWatcher) $scope.featureWatcher();

                if ($scope.packageWatcher) $scope.packageWatcher();

                $scope.loading = true;
                $http({ method: "GET", url: "api/v1/adminpanel/" + $scope.model.accountId }).success(
                    function (data) {
                        for (var i in data.features) {
                            data.features[i].active = data.features[i].status === 1;
                        }

                        $scope.currentAccount = data;
                        $scope.currentAccount.selectedRoles = _.pluck(
                            _.filter($scope.currentAccount.roles, function (r) {
                                return r.enabled;
                            }),
                            "role"
                        );

                        if (!$scope.currentAccount.package) {
                            $scope.currentAccount.package = "nothing";
                        }

                        $scope.selectedPackage = $scope.currentAccount.package;
                        $scope.selectedIndustry = $scope.currentAccount.industry;
                        $scope.selectedSalesRep = $scope.currentAccount.salesRep;
                        $scope.selectedCsm = $scope.currentAccount.customerSuccessManager || "Not set";
                        $scope.selectedLoginType = { key: $scope.currentAccount.defaultUserLoginType };

                        if ($scope.currentAccount.impressions) {
                            $scope.chartConfigImpr.series.push({
                                data: $scope.currentAccount.impressions.chartData
                            });
                        }

                        $scope.loading = false;

                        $scope.featureWatcher = $scope.$watch(
                            "currentAccount.features",
                            function (n, o) {
                                if (n === o) return;

                                $scope.isFeaturesDirty = true;
                            },
                            true
                        );

                        $scope.packageWatcher = $scope.$watch(
                            "selectedPackage",
                            function (n, o) {
                                if (n === o) return;

                                $scope.isPackageDirty = true;
                            },
                            true
                        );
                    }
                );
            };

            $scope.init();

            $scope.saveFeatures = function () {
                if ($scope.isFeaturesDirty) {
                    for (var i in $scope.currentAccount.features) {
                        $scope.currentAccount.features[i].status = $scope.currentAccount.features[i]
                            .active
                            ? 1
                            : 2;
                    }

                    var data = {
                        features: $scope.currentAccount.features
                    };

                    return $http({
                        method: "POST",
                        url: "api/v1/adminpanel/" + $scope.model.accountId,
                        data: data
                    })
                        .success(function () {})
                        .error(function (data) {
                            $scope.loading = false;
                            console.log(data);
                            alert("ERROR: Can not save features!");
                        })
                        .finally(function () {
                            $scope.isFeaturesDirty = false;
                        });
                }
            };

            $scope.saveSelectedPackage = function () {
                if ($scope.isPackageDirty) {
                    var selectedPackage = $scope.selectedPackage;
                    var currentPackage = $scope.currentAccount.package;

                    return $http({
                        method: "POST",
                        url: "api/v1/" + $scope.currentAccount.slug + "/admin/package",
                        data: angular.toJson(selectedPackage)
                    })
                        .success(function () {
                            _.findWhere($scope.model.data, {
                                slug: $scope.currentAccount.slug
                            }).package = selectedPackage;
                            $scope.currentAccount.package = $scope.selectedPackage;
                        })
                        .error(function (data) {
                            $scope.loading = false;
                            $scope.currentAccount.package = currentPackage;
                            console.log(data);
                            alert("ERROR: Can not save package!");
                        })
                        .finally(function () {
                            $scope.isPackageDirty = false;
                        });
                }
            };

            $scope.saveSettings = function () {
                $scope.loading = true;

                $q.all([$scope.saveFeatures(), $scope.saveSelectedPackage()]).then(function () {
                    $scope.loading = false;
                });
            };

            $scope.navigationTab = "info";
            $scope.kpi = "users";
            $scope.packages = [
                { id: "CMP", value: "CMP" },
                { id: "On Demand", value: "On Demand" }
            ];

            $scope.closeDetailView = function (details) {
                $("#detailBackdrop").css("opacity", "0");
                $("#account-details").removeClass("in");
                $scope.$parent.detailsOpen = false;
            };

            $scope.featureClick = function (feature) {
                //feature.active = !feature.active;

                if (!feature.active) {
                    _.each(feature.dependencies, function (dep) {
                        var find = _.find($scope.currentAccount.features, function (f) {
                            return f.id === dep;
                        });

                        if (find) {
                            find.active = true;
                        }
                    });

                    feature.active = true;
                } else {
                    var hasDependencies = false;
                    for (var i in $scope.currentAccount.features) {
                        var find = _.find($scope.currentAccount.features[i].dependencies, function (d) {
                            return d === feature.id && $scope.currentAccount.features[i].active;
                        });

                        if (find) {
                            hasDependencies = true;
                        }
                    }

                    if (!hasDependencies) feature.active = false;
                }
            };

            // --------------- Highcharts ------------------

            // Global settings
            Highcharts.setOptions({
                options: {
                    chart: {
                        spacing: [0, 0, 0, 0],
                        animation: false
                    }
                },
                title: {
                    text: ""
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    labels: {
                        enabled: true,
                        align: "center",
                        style: {
                            color: "#aaa",
                            fontFamily: "Droid Sans",
                            fontSize: "10px"
                        }
                    },
                    lineWidth: 0
                },
                yAxis: {
                    gridLineColor: "#f5f5f5",
                    labels: {
                        enabled: true,
                        x: -5,
                        style: {
                            color: "#aaa",
                            fontFamily: "Droid Sans",
                            fontSize: "10px"
                        }
                    },
                    title: {
                        text: ""
                    },
                    showFirstLabel: false
                },
                series: [
                    {
                        showInLegend: false,
                        data: []
                    }
                ]
            });

            // Downloads config
            $scope.chartConfigDownloads = {
                options: {
                    chart: {
                        type: "areaspline",
                        marginBottom: 20,
                        marginTop: 15
                    },
                    tooltip: {
                        useHTML: true,
                        backgroundColor: "#34364d",
                        shadow: false,
                        borderWidth: 0,
                        borderRadius: 3,
                        style: {
                            color: "#ffffff",
                            fontFamily: "Droid Sans",
                            fontSize: "14px",
                            boxShadow: "none"
                        }
                    },
                    plotOptions: {
                        series: {
                            animation: false
                        },
                        areaspline: {
                            fillOpacity: 0.9,
                            color: "#33A0C0",
                            marker: {
                                enabled: false,
                                radius: 4,
                                symbol: "circle"
                            },
                            lineWidth: 0,
                            showInLegend: false
                        }
                    }
                },
                xAxis: {
                    type: "datetime"
                },
                series: [
                    {
                        name: "Downloads",
                        data: []
                    }
                ]
            };

            // Done banners config
            $scope.chartConfigDonebanners = {
                options: {
                    chart: {
                        type: "areaspline",
                        marginBottom: 20,
                        marginTop: 15
                    },
                    tooltip: {
                        useHTML: true,
                        backgroundColor: "#34364d",
                        shadow: false,
                        borderWidth: 0,
                        borderRadius: 3,
                        style: {
                            color: "#ffffff",
                            fontFamily: "Droid Sans",
                            fontSize: "14px",
                            boxShadow: "none"
                        }
                    },
                    plotOptions: {
                        series: {
                            animation: false
                        },
                        areaspline: {
                            fillOpacity: 0.9,
                            color: "#33A0C0",
                            marker: {
                                enabled: false,
                                radius: 4,
                                symbol: "circle"
                            },
                            lineWidth: 0,
                            showInLegend: false
                        }
                    }
                },
                xAxis: {
                    type: "datetime"
                },
                series: [
                    {
                        name: "Done banners",
                        data: []
                    }
                ]
            };

            // Impressions config
            $scope.chartConfigImpr = {
                options: {
                    chart: {
                        type: "areaspline",
                        marginBottom: 20,
                        marginTop: 15
                    },
                    tooltip: {
                        useHTML: true,
                        backgroundColor: "#34364d",
                        shadow: false,
                        borderWidth: 0,
                        borderRadius: 3,
                        style: {
                            color: "#ffffff",
                            fontFamily: "Droid Sans",
                            fontSize: "14px",
                            boxShadow: "none"
                        }
                    },
                    plotOptions: {
                        series: {
                            animation: false
                        },
                        areaspline: {
                            fillOpacity: 0.9,
                            color: "#33A0C0",
                            marker: {
                                enabled: false,
                                radius: 4,
                                symbol: "circle"
                            },
                            lineWidth: 0,
                            showInLegend: false
                        }
                    }
                },
                xAxis: {
                    type: "datetime"
                },
                series: [
                    {
                        name: "Impressions",
                        data: []
                    }
                ]
            };

            // Revenue config
            $scope.chartConfigRevenue = {
                options: {
                    chart: {
                        type: "areaspline",
                        marginBottom: 20,
                        marginTop: 15
                    },
                    tooltip: {
                        useHTML: true,
                        backgroundColor: "#34364d",
                        shadow: false,
                        borderWidth: 0,
                        borderRadius: 3,
                        style: {
                            color: "#ffffff",
                            fontFamily: "Droid Sans",
                            fontSize: "14px",
                            boxShadow: "none"
                        }
                    },
                    plotOptions: {
                        series: {
                            animation: false
                        },
                        areaspline: {
                            fillOpacity: 0.9,
                            color: "#33A0C0",
                            marker: {
                                enabled: false,
                                radius: 4,
                                symbol: "circle"
                            },
                            lineWidth: 0,
                            showInLegend: false
                        }
                    }
                },
                xAxis: {
                    type: "datetime"
                },
                series: [
                    {
                        name: "Revenue",
                        data: []
                    }
                ]
            };

            if ($scope.currentAccount != null) {
            }

            // ----------- ACCOUNT ACTIONS ------------ //

            $scope.changeCompanyName = function () {
                var name = prompt("Enter the new Company Name", "");

                if (name) {
                    $http({
                        method: "POST",
                        url: "api/v1/" + $scope.currentAccount.slug + "/admin/rename",
                        data: angular.toJson(name)
                    }).success(function (data) {
                        $scope.currentAccount.companyName = name;
                        _.findWhere($scope.model.data, {
                            slug: $scope.currentAccount.slug
                        }).companyName = name;
                        alert("Company name changed to " + name + "!");
                    });
                }
            };

            $scope.changeCustomerNumber = function () {
                var customerNumber = prompt("Enter the new Customer Number", "");

                if (customerNumber) {
                    $http({
                        method: "POST",
                        url: "api/v1/" + $scope.currentAccount.slug + "/admin/setcustomernumber",
                        data: angular.toJson(customerNumber)
                    })
                        .success(function (data) {})
                        .success(function (data) {
                            $scope.currentAccount.customerNumber = customerNumber;
                            _.findWhere($scope.model.data, {
                                slug: $scope.currentAccount.slug
                            }).customerNumber = customerNumber;
                            alert("Customer number changed to " + customerNumber + "!");
                        })
                        .error(function (data) {
                            alert("Customer number could not be changed!");
                        });
                }
            };

            $scope.$watch("selectedIndustry", function (newVal, oldVal) {
                if (newVal === oldVal) return;

                if (newVal === $scope.currentAccount.industry) return;

                var industry = angular.copy($scope.selectedIndustry);
                $http({
                    method: "POST",
                    url: "api/v1/" + $scope.currentAccount.slug + "/admin/industry",
                    data: angular.toJson(industry)
                }).success(function (data) {
                    $scope.currentAccount.industry = industry;
                    _.findWhere($scope.model.data, { slug: $scope.currentAccount.slug }).industry =
                        industry;
                    alert('Industry set to "' + $scope.currentAccount.industry + '"!');
                });
            });

            $scope.$watch("currentAccount.selectedRoles", function (newVal, oldVal) {
                if (newVal === oldVal || !oldVal) return;

                var roles =
                    $scope.currentAccount.selectedRoles === "None"
                        ? ""
                        : $scope.currentAccount.selectedRoles;

                $scope.updateRoles(roles);
            });

            $scope.$watch("selectedSalesRep", function (newVal, oldVal) {
                if (newVal === oldVal) return;

                if (newVal === $scope.currentAccount.salesRep) return;

                var salesRep = angular.copy($scope.selectedSalesRep);
                $http({
                    method: "POST",
                    url: "api/v1/" + $scope.currentAccount.slug + "/admin/salesrep",
                    data: angular.toJson(salesRep)
                }).success(function (data) {
                    $scope.currentAccount.salesRep = salesRep;
                    _.findWhere($scope.model.data, { slug: $scope.currentAccount.slug }).salesRep =
                        salesRep;
                    alert('SalesRep set to "' + $scope.currentAccount.salesRep + '"!');
                });
            });

            $scope.$watch("selectedCsm", function (newVal, oldVal) {
                if (newVal === oldVal) return;

                if (newVal === $scope.currentAccount.customerSuccessManager) return;

                var clearCsm = $scope.selectedCsm === "Not set";
                var csm = angular.copy($scope.selectedCsm);
                console.log(csm);
                $http({
                    method: "POST",
                    url: "api/v1/" + $scope.currentAccount.slug + "/admin/csm",
                    data: angular.toJson(csm)
                }).success(function (data) {
                    $scope.currentAccount.customerSuccessManager = clearCsm ? null : csm;
                    _.findWhere($scope.model.data, {
                        slug: $scope.currentAccount.slug
                    }).customerSuccessManager = clearCsm ? null : csm;
                    if (clearCsm) alert("Csm cleared!");
                    else alert('Csm set to "' + $scope.currentAccount.customerSuccessManager + '"!');
                });
            });

            $scope.$watch(
                "selectedLoginType",
                function (newVal, oldVal) {
                    if (oldVal === undefined) return;

                    if (newVal === oldVal) return;

                    if (newVal.key === $scope.currentAccount.defaultUserLoginType.key) return;

                    $http({
                        method: "POST",
                        url: "api/v1/" + $scope.currentAccount.slug + "/admin/defaultlogintype",
                        data: angular.toJson(newVal.key)
                    }).success(function (data) {
                        $scope.currentAccount.defaultUserLoginType = newVal;
                    });
                },
                true
            );

            $scope.changeEmailSettings = function () {
                var method = $scope.currentAccount.isIncludedInEmailReports ? "exclude" : "include";
                $http({
                    method: "GET",
                    url: "api/v1/" + $scope.currentAccount.slug + "/admin/emails/" + method
                }).success(function (data) {
                    _.findWhere($scope.model.data, {
                        slug: $scope.currentAccount.slug
                    }).isIncludedInEmailReports = !$scope.currentAccount.isIncludedInEmailReports;
                    $scope.currentAccount.isIncludedInEmailReports =
                        !$scope.currentAccount.isIncludedInEmailReports;
                });
            };

            $scope.resendConfirmationEmail = function () {
                var resend = confirm(
                    "Resend confirmation email to " + $scope.currentAccount.email + "?"
                );
                if (resend) {
                    $http({
                        method: "GET",
                        url:
                            "api/v1/" + $scope.currentAccount.slug + "/admin/emails/resend/confirmation"
                    }).success(function (data) {
                        alert("Email resent to " + $scope.currentAccount.email + "!");
                    });
                }
            };

            $scope.upgradeAccount = function () {
                var plan = prompt("Upgrade account plan, enter SMB1, SMB2 or Pro", "");

                //Check if input is integer
                if (plan && (plan === "SMB1" || plan === "SMB2" || plan === "Pro")) {
                    $scope.loading = true;
                    $http({
                        method: "GET",
                        url: " /api/v1/{0}/admin/upgrade/{1}".format($scope.currentAccount.slug, plan)
                    }).success(function (data) {
                        alert("Account upgraded to " + plan + "!");
                        $scope.init();
                    });
                }
            };

            $scope.extendTrial = function () {
                var extraDays = prompt("In how many days should the trial end (from today)?", "");
                //Check if input is integer
                if (extraDays) {
                    if (!isNaN(extraDays) && extraDays % 1 === 0 && extraDays > 0 && extraDays <= 100) {
                        $http({
                            method: "GET",
                            url:
                                "api/v1/" +
                                $scope.currentAccount.slug +
                                "/admin/extendTrial/" +
                                extraDays
                        }).success(function (data) {
                            alert("Trial extended " + extraDays + " days!");
                        });
                    } else alert("Input must be a number between 1 and 100");
                }
            };

            $scope.addImpressions = function () {
                var extraImpr = prompt("Add how many extra impressions?", "");
                //Check if input is integer
                if (extraImpr) {
                    if (
                        !isNaN(extraImpr) &&
                        extraImpr % 1 === 0 &&
                        extraImpr > 0 &&
                        extraImpr <= 100000000
                    ) {
                        $http({
                            method: "GET",
                            url:
                                "api/v1/" +
                                $scope.currentAccount.slug +
                                "/admin/extraimpressions/" +
                                extraImpr
                        }).success(function (data) {
                            alert(extraImpr + " impressions added!");
                        });
                    } else alert("Input must be a number between 1 and 100 000 000");
                }
            };

            $scope.generatePlacements = function () {
                var generate = confirm("Re-generate all banners?");
                if (generate) {
                    $http({
                        method: "GET",
                        url: "api/v1/" + $scope.currentAccount.slug + "/admin/regenerate/banners/"
                    }).success(function (data) {
                        alert(data + " banners queued up to be re-generated!");
                    });
                }
            };

            $scope.removePersonalData = function () {
                var generate = confirm(
                    "Warning! Personal data including user logins will be removed without ability to restore. Are you sure you want to remove the personal data?"
                );
                if (generate) {
                    $http({
                        method: "POST",
                        url: "api/v1/accounts/gdprcleanup/" + $scope.currentAccount.id
                    })
                        .success(function (data) {
                            alert("Personal data was removed successfully!");
                        })
                        .error(function () {
                            alert(
                                "Your request has been failed! Personal data was not removed successfully!"
                            );
                        });
                }
            };

            $scope.toggleAccountOpenOrClose = function () {
                if ($scope.currentAccount.closed === false) {
                    $scope.closeAccount();
                } else if ($scope.currentAccount.closed === true) {
                    $scope.reopenAccount();
                    $scope.currentAccount.closed = false;
                }
            };

            $scope.closeAccount = function () {
                var close = confirm("Are you sure you want to close the account?");
                if (close) {
                    $http({
                        method: "POST",
                        url: "api/v1/accounts/close/" + $scope.currentAccount.id
                    }).success(function () {
                        alert("Account closed");
                        $scope.currentAccount.closed = true;
                    });
                }
            };

            $scope.reopenAccount = function () {
                $http({
                    method: "POST",
                    url: "api/v1/accounts/reopen/" + $scope.currentAccount.id
                }).success(function () {
                    alert("Account reopened!");
                    $scope.currentAccount.closed = false;
                });
            };

            $scope.changePlan = function () {
                var planCode = prompt("Change plan? Enter plan code...", "");
                if (planCode) {
                    $http({
                        method: "GET",
                        url: "api/v1/" + $scope.currentAccount.slug + "/admin/plan/change/" + planCode
                    }).success(function (data) {
                        alert("Plan changed to: " + planCode);
                    });
                }
            };

            $scope.addStoreCredits = function () {
                var credits = prompt("Add credits to account", "1000");
                if (credits > 0 && credits < 100000) {
                    $http({
                        method: "POST",
                        url:
                            "api/v1/adminpanel/storecredits/" +
                            $scope.currentAccount.id +
                            "?credits=" +
                            credits
                    }).success(function (data) {
                        alert("Added: " + credits + " credits.");
                    });
                }
            };

            $scope.updateRoles = function (roles) {
                var wrapper = { roles: roles };

                $http({
                    method: "POST",
                    url: "api/v1/adminpanel/roles/" + $scope.currentAccount.id,
                    data: angular.toJson(wrapper)
                }).success(function (data) {});
            };
        }
    );
