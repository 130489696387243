﻿"use strict";

/* Controllers */
var _START_SHOWING_COUNT = 100;
var _CURRENT_SHOWING_COUNT = 100;
var _MAX_SHOWING = false;

angular
    .module("AccountManagerApp")
    .controller(
        "AccountsController",
        function AccountsController($scope, $rootScope, $timeout, $http, ngDialog) {
            $scope.model = {
                loading: false,
                accountOptions: new Array(),
                slugFilter: "",
                includeInternal: location.hostname === "login.bannerflow.com" ? false : true,
                includeClosedAccounts: false,
                showType: "ENTERPRISE",
                accounts: new Array(),
                pointAvarage: 0,
                bannerFlowUrl: bannerFlowUrl,
                homeUrl: window["homeUrl"],
                salesreps: window.viewData.salesReps,
                csms: window.viewData.csms,
                ranges: {
                    "All time": [moment("2014-01-01"), moment()],
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract("days", 1), moment().subtract("days", 1)],
                    "Last 7 Days": [moment().subtract("days", 6), moment()],
                    "Last 30 Days": [moment().subtract("days", 29), moment()],
                    "This Month": [moment().startOf("month"), moment().endOf("month")],
                    "Last Month": [
                        moment().subtract("month", 1).startOf("month"),
                        moment().subtract("month", 1).endOf("month")
                    ]
                },
                sweLocale: { firstDay: 1 },
                dateRange: { startDate: moment("2014-01-01"), endDate: moment() },
                hasPressedShowAll: false,
                datePicker: { startDate: moment(new Date()) },
                accountId: null,
                detailsOpen: false,
                selfServicePlans: [
                    "Standard",
                    "Professional",
                    "Premium",
                    "Small",
                    "Medium",
                    "Large",
                    "Start",
                    "Grow"
                ],
                selectedSSPlans: []
            };

            $scope.getAccounts = function () {
                $scope.model.loading = true;
                var closed = $scope.model.includeClosedAccounts ? "?includeAll=true" : "";
                $http({ method: "GET", url: "api/v1/adminpanel/all" + closed }).success(
                    function (data) {
                        $scope.model.data = data;
                        $scope.doSearch();
                    }
                );
            };

            $scope.getAccounts();

            $scope.$watch("model.slugFilter", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.$watch("model.showType", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.$watch("model.includeInternal", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.$watch("model.includeClosedAccounts", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.getAccounts();
            });

            $scope.$watch("model.dateRange.startDate", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.$watch("model.dateRange.endDate", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.$watch("model.selectedSSPlans", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.$watch("model.selectedSalesReps", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.$watch("model.selectedCsm", function (newVal, oldVal) {
                if (newVal === oldVal) return;
                $scope.doSearch();
            });

            $scope.getTitle = function () {
                switch ($scope.model.showType) {
                    case "SA":
                        return "Small advertiser accounts";
                    case "ENTERPRISE":
                        return "Enterprise accounts";
                    case "AGENCY":
                        return "Agency accounts";
                    case "PARTNER":
                        return "Partner accounts";
                    case "TRIAL":
                        return "Trial accounts";
                }
            };

            $scope.isPlanCodes = function (plan, planCodes) {
                for (var i = 0; i < planCodes.length; i++) {
                    if (plan === planCodes[i]) {
                        return true;
                    }
                }
                return false;
            };

            $scope.doSearch = function () {
                $scope.model.loading = true;
                $timeout(function () {
                    $scope.model.accounts = new Array();
                    for (var i = 0; i < $scope.model.data.length; i++) {
                        var res = $scope.search($scope.model.data[i]);
                        if (res) {
                            $scope.model.accounts.push(res);
                        }
                    }
                    $scope.model.loading = false;
                }, 50);
            };

            $scope.search = function (item) {
                if (!$scope.model.accounts) return null;

                //Is the account the right type?
                if (
                    ($scope.model.showType === "SA" && item.type === "SA") ||
                    ($scope.model.showType === "AGENCY" && item.type === "AGENCY") ||
                    ($scope.model.showType === "ENTERPRISE" && item.type === "ENTERPRISE") ||
                    ($scope.model.showType === "PARTNER" && item.type === "PARTNER") ||
                    ($scope.model.showType === "TRIAL" && item.type === "TRIAL")
                ) {
                    //Is the account the right kind of plan/category?
                    if (
                        ($scope.model.showType === "SA" &&
                            ($scope.model.selectedSSPlans.length == 0 ||
                                ($scope.isPlanCodes(item.plan, ["bf-1-m", "bf-1-y"]) &&
                                    $scope.model.selectedSSPlans.indexOf("Standard") != -1) ||
                                ($scope.isPlanCodes(item.plan, ["bf-2-m", "bf-2-y"]) &&
                                    $scope.model.selectedSSPlans.indexOf("Professional") != -1) ||
                                ($scope.isPlanCodes(item.plan, ["bf-3-m", "bf-3-y"]) &&
                                    $scope.model.selectedSSPlans.indexOf("Premium") != -1) ||
                                ($scope.isPlanCodes(item.plan, ["bf-s-m", "bf-s-y"]) &&
                                    $scope.model.selectedSSPlans.indexOf("Small") != -1) ||
                                ($scope.isPlanCodes(item.plan, ["bf-m-m", "bf-m-y"]) &&
                                    $scope.model.selectedSSPlans.indexOf("Medium") != -1) ||
                                ($scope.isPlanCodes(item.plan, ["bf-l-m", "bf-l-y"]) &&
                                    $scope.model.selectedSSPlans.indexOf("Large") != -1) ||
                                ($scope.isPlanCodes(item.plan, ["bf-smb1-y", "bf-smb1-6m"]) &&
                                    $scope.model.selectedSSPlans.indexOf("Start") != -1) ||
                                ($scope.isPlanCodes(item.plan, ["bf-smb2-y", "bf-smb2-6m"]) &&
                                    $scope.model.selectedSSPlans.indexOf("Grow") != -1))) ||
                        $scope.model.showType === "AGENCY" ||
                        $scope.model.showType === "ENTERPRISE" ||
                        ($scope.model.showType === "PARTNER" && item.type === "PARTNER") ||
                        $scope.model.showType === "TRIAL"
                    ) {
                        //Is the account created within the date range?
                        if (
                            item.type != "SA" ||
                            (!moment(item.created).isBefore($scope.model.dateRange.startDate) &&
                                !moment(item.created).isAfter($scope.model.dateRange.endDate))
                        ) {
                            //Is the account an internal account?
                            if (
                                $scope.model.showType != "SA" ||
                                $scope.model.includeInternal ||
                                (!$scope.model.includeInternal &&
                                    !(
                                        "" + item.email.indexOf("nordicfactory.com") != -1 ||
                                        "" + item.email.indexOf("@bannerflow.com") != -1 ||
                                        "" + item.email.indexOf("@translationflow.com") != -1
                                    ))
                            ) {
                                var includeBlankCsm =
                                    $scope.model.selectedCsm != null &&
                                    $scope.model.selectedCsm.indexOf("Not set") !== -1 &&
                                    item.customerSuccessManager == null;

                                var salesRepsNotSelected =
                                    $scope.model.selectedSalesReps == null ||
                                    $scope.model.selectedSalesReps.length == 0 ||
                                    $scope.model.selectedSalesReps.indexOf(item.salesRep) != -1;
                                var csmsNotSelected =
                                    $scope.model.selectedCsm == null ||
                                    $scope.model.selectedCsm.length == 0 ||
                                    $scope.model.selectedCsm.indexOf(item.customerSuccessManager) !=
                                        -1 ||
                                    includeBlankCsm;
                                var slugFilter = $scope.model.slugFilter.toLowerCase();
                                var slug = item.slug.toLowerCase().indexOf(slugFilter) != -1;
                                var plan = item.plan.toLowerCase().indexOf(slugFilter) != -1;
                                var email =
                                    item.email && item.email.toLowerCase().indexOf(slugFilter) != -1;
                                var salesRep =
                                    item.salesRep !== null &&
                                    item.salesRep.toLowerCase().indexOf(slugFilter) != -1;
                                var companyName =
                                    (item.companyName &&
                                        item.companyName.toLowerCase().indexOf(slugFilter) != -1) ||
                                    salesRep;
                                var csm =
                                    includeBlankCsm ||
                                    (item.customerSuccessManager != null &&
                                        item.customerSuccessManager.toLowerCase().indexOf(slugFilter) !=
                                            -1);

                                if (
                                    (slug || plan || email || salesRep || companyName || csm) &&
                                    salesRepsNotSelected &&
                                    csmsNotSelected
                                ) {
                                    return item;
                                }
                            }
                        }
                    }
                }
                return null;
            };

            $scope.isMaxShowing = function () {
                return _MAX_SHOWING;
            };

            $scope.showMore = function () {
                _CURRENT_SHOWING_COUNT += 25;
                $scope.model.hasPressedShowAll = true;
            };

            $scope.createEnterpriseAccount = function () {
                ngDialog.open({
                    className: "duplicate-dialog",
                    template: "/legacy-app/views/dialogs/create-account.html",
                    appendTo: "body",
                    controller: "CreateAccountDialogController",
                    data: {
                        type: "enterprise"
                    }
                });
            };

            $scope.createAgencyAccount = function () {
                ngDialog.open({
                    className: "duplicate-dialog",
                    template: "/legacy-app/views/dialogs/create-account.html",
                    appendTo: "body",
                    controller: "CreateAccountDialogController",
                    data: {
                        type: "agency"
                    }
                });
            };

            $scope.createTrialAccount = function () {
                ngDialog.open({
                    className: "duplicate-dialog",
                    template: "/legacy-app/views/dialogs/create-account.html",
                    appendTo: "body",
                    controller: "CreateAccountDialogController",
                    data: {
                        type: "trial"
                    }
                });
            };

            $scope.getAccount = function (id) {
                $scope.model.accountId = id;
                $scope.model.detailsOpen = true;
            };

            $scope.closeTrialAccount = function (accountId) {
                var close = confirm("Are you sure you want to close the account?");
                if (close) {
                    $http({ method: "POST", url: "api/v1/trial/close/" + accountId }).success(
                        function (data) {
                            alert("Account closed");
                        }
                    );
                }
            };

            $scope.reopenTrialAccount = function (accountId) {
                console.log("egehe");
                var extraDays = prompt("In how many days should the trial end (from today)?", "");
                if (extraDays) {
                    if (!isNaN(extraDays) && extraDays % 1 === 0 && extraDays > 0 && extraDays <= 100) {
                        $http({
                            method: "POST",
                            url: "api/v1/trial/reopen/" + accountId + "/" + extraDays
                        }).success(function (data) {
                            alert("Account reopened and extended for " + extraDays + " days!");
                        });
                    } else alert("Input must be a number between 1 and 100");
                }
            };

            $scope.extendTrial = function () {
                var extraDays = prompt("In how many days should the trial end (from today)?", "");
                //Check if input is integer
                if (extraDays) {
                    if (!isNaN(extraDays) && extraDays % 1 === 0 && extraDays > 0 && extraDays <= 100) {
                        $http({
                            method: "GET",
                            url:
                                "api/v1/" +
                                $scope.currentAccount.slug +
                                "/admin/extendTrial/" +
                                extraDays
                        }).success(function (data) {
                            alert("Trial extended " + extraDays + " days!");
                        });
                    } else alert("Input must be a number between 1 and 100");
                }
            };

            $scope.convertAccount = function (accountId) {
                var typeOfPlan = prompt("What plan to convert to? Agency or Enterprise?", "");
                if (typeOfPlan) {
                    if (
                        typeOfPlan.toLowerCase() === "agency" ||
                        typeOfPlan.toLowerCase() === "enterprise"
                    ) {
                        $http({
                            method: "POST",
                            url: "api/v1/trial/convert/" + accountId + "/" + typeOfPlan
                        }).success(function (data) {
                            alert("Account coverted to " + typeOfPlan);
                        });
                    } else alert("Input must be eaither Agency or Enterprise");
                }
            };
        }
    );
